define('gemini/components/settings-menu', ['exports', 'gemini/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    settings: _environment.default.settingsPageOptions

  });
});