define('gemini/controllers/app/push/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    saveUpdate: "",

    actions: {
      send: function send() {

        var item = this.store.createRecord('notification', {
          title: this.get('title'),
          body: this.get('message'),
          createdAt: new Date(),
          createdAtI: -new Date().getTime()
        });

        this.set('title', '');
        this.set('message', '');
        this.set('saveUpdate', "Toegevoegd!");

        this.transitionToRoute('app.push');

        item.save().catch(function (e) {
          console.warn(e, e.errors);
        });
      },
      cancel: function cancel() {
        this.set('title', '');
        this.set('message', '');
        this.transitionToRoute('app.push');
      }
    }
  });
});