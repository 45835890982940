define('gemini/helpers/eq_minus_one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.eq_minus_one = eq_minus_one;
    function eq_minus_one(params) {
        return params[0] === params[1] - 1;
    }

    exports.default = Ember.Helper.helper(eq_minus_one);
});