define('gemini/components/fb-log-pagination', ['exports', 'gemini/components/fb-pagination'], function (exports, _fbPagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fbPagination.default.extend({

    i18n: Ember.inject.service(),
    pageLimit: 20,
    start: null

  });
});