define("gemini/helpers/get_month", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.get_month = get_month;
    function get_month(params) {
        var months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
        return months[params[0]];
    }

    exports.default = Ember.Helper.helper(get_month);
});