define("gemini/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    "add": "Add",
    "cancel": "Cancel",
    "remove": "Remove",
    "save": "Save",
    "saved": "Saved",
    "saving": "Saving",
    "confirm": "Confirm",
    "component": "Component",
    "sign-in": "Sign in",

    "active": "Active",
    "inactive": "Inactive",
    "disabled": "Disabled",

    "visible": "Visible",
    "hidden": "Hidden",

    "true": "True",
    "false": "False",

    "index": {
      "in-menu": "Dashboard",
      "in-toolbar": "Dashboard"
    },

    "contact": {
      "in-menu": "Contact",
      "in-toolbar": "Contact",

      "message": {
        "in-toolbar": "Contact / Message",
        "message": "Message",
        "emailaddress": "E-mail Address",
        "reply": "Reply"
      }
    },

    "analytics": {
      "in-menu": "Analytics"
    },

    "faq": {
      "in-menu": "FAQ",
      "in-toolbar": "Frequently Asked Questions",
      "question": "Question",
      "add-title": "Add FAQ Question",

      "add": {
        "in-toolbar": "Frequently Asked Questions / Add Item"
      }
    },

    "info": {
      "in-menu": "Information",
      "in-toolbar": "Information",

      "short": "short",
      "detailed": "detailed",

      "short-description": "Short description",
      "title": "Title"
    },

    "news": {
      "in-menu": "News",
      "in-toolbar": "News",
      "subject": "Subject",
      "message": "Message",
      "add-title": "Add News Item",
      "send-push-notification": "Send push notification",
      "visible": "Visible",
      "date": "Date",

      "add": {
        "in-toolbar": "News / Add Item"
      }
    },

    "push": {
      "in-menu": "Pushnotifications",
      "in-toolbar": "Pushnotifications",
      "new-notification": "New push message",

      "title": "Title",
      "message": "Message",
      "send": "Send",

      "sent": "Sent",
      "date": "Date",
      "new": "New message"
    },

    "files": {
      "in-menu": "Files",
      "in-toolbar": "Files",

      "file": "File",
      "file-name": "File name",

      "item": {
        "in-toolbar": "Files / File"
      },
      "add": {
        "in-title": "Add File",
        "in-toolbar": "Files / Add File"
      }
    },

    "settings": {
      "in-menu": "Settings",
      "in-toolbar": "General Settings",

      "setting": {
        "projections": "Projections",
        "descriptions": "Descriptions",
        "contact": "Contact Form"
      },

      "descriptions": {
        "select-component": "Please select a component.",

        "live": {
          "windspeed": "Windspeed",
          "winddirection": "Wind direction",
          "co2": "CO2",
          "households": "Households",
          "turbine-status": "Turbine Status",
          "waveheight": "Waveheight"
        },
        "history": {
          "production": "Total production",
          "hours": "Full load hours",
          "co2": "CO2",
          "households": "Households"
        },
        "other": {
          "privacy": "Privacy Policy",
          "disclaimer": "Disclaimer",
          "licenses": "Open Licenses"
        },
        "status": {
          "wind-high": "Too much wind",
          "wind-low": "Too little wind",
          "maintenance": "Maintenance",
          "environment": "Bird/bat",
          "ice": "Ice"
        },
        "groups": {
          "live": "Live",
          "history": "Historical",
          "other": "Other"
        }
      }
    },

    "signout": {
      "in-menu": "Sign out"
    },

    "users": {
      "in-menu": "User management",
      "in-toolbar": "User management",
      "last-signed-in": "Last Signed In",

      "name": "Name",
      "email": "E-mail address",
      "devices": "Devices",
      "device-name": "Device Name",
      "device-allow-access": "Allow Access",
      "device-allowed": "Allowed",
      "device-not-allowed": "Not allowed",
      "number-of-devices": "# Devices",
      "sign-out-device": "Sign out",

      "reset-password": "Reset Password",
      "password-reset-email-sent": "E-mail with password reset instructions was sent.",

      "add": {
        "in-toolbar": "User management / Add User",
        "title": "Add User"
      },
      "user": {
        "in-toolbar": "User management / Overview User"
      },

      "errors": {
        "invalid-email": "Please enter a valid e-mail address.",
        "email-already-in-use": "This e-mail address is already in use."
      }
    },

    "logs": {
      "in-menu": "Logs",
      "in-toolbar": "Logs",

      "source": "Source",
      "time": "Time",
      "action": "Action",
      "level": "Level",
      "user": "User",
      "device": "Device",
      "ip": "IP Address",
      "result": "Result",
      "error": "Error",

      "levels": {
        "default": "Normal"
      }
    },

    "misc": {
      "feedback": "Feedback"
    },

    // components
    "c": {
      "fb-pagination": {
        "search": "Search",
        "previous": "previous",
        "next": "next"
      },
      "content-manager": {
        "types": {
          "text": "Text",
          "image": "Image",
          "movie": "Video",
          "two-columns": "Two columns",
          "link": "Link",
          "heading": "Heading"
        },
        "link": {
          "text": "Link text",
          "location": "Link location"
        },
        "movie": {
          "url": "Movie URL",
          "explanation": "Enter a Vimeo URL (e.g. \"https://vimeo.com/12345678\")"
        }
      },
      "paper-remove-button": {
        "confirm": "confirm"
      },
      "feedback-manager": {
        "comments": "Comments",
        "no-comments": "There are no comments yet.",
        "no-feedback": "There is no feedback available yet."
      },
      "image-uploader": {
        "select": "select"
      },
      "private-file-upload": {
        "uploaded": "File has been uploaded"
      }
    }
  };
});