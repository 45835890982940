define('gemini/controllers/app/logs/item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('app.logs');
      }
    }
  });
});