define('gemini/controllers/app/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ref: Ember.inject.service('firebase'),

        // windspeed: null,

        actions: {
            // setWindSpeed: function(windspeed){
            //     let ref = this.get('ref');
            //     ref.child('/tmp/windspeed').set(windspeed);
            // }
        }

        // init: function(){
        //     var _this = this;
        //     let ref = this.get('ref');
        //
        //     ref.child('/tmp/windspeed').on("value", function (snapshot) {
        //         _this.setProperties({windspeed: snapshot.val()});
        //     });
        //
        //     console.log('currentRouteName', this.get('appCtrl'))
        // }
    });
});