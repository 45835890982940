define('gemini/routes/app/news.item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('faq', params.newsitem_id);
    }
  });
});