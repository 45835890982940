define('gemini/controllers/app/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ref: Ember.inject.service('firebase'),

        // privacy: null,
        // disclaimer: null,
        // licenses: null,

        // turbines: [],

        actions: {
            // saveText: function(){
            //     let ref = this.get('ref');
            //     ref.child('info/privacy').set(this.get('privacy'));
            //     ref.child('info/disclaimer').set(this.get('disclaimer'));
            //     ref.child('info/licenses').set(this.get('licenses'));
            // },
            // setStatus0: function(turbineId){
            //     let ref = this.get('ref');
            //     ref.child('live-data/turbine-status/' + turbineId).set(0);
            // },
            // setStatus1: function(turbineId){
            //     let ref = this.get('ref');
            //     ref.child('live-data/turbine-status/' + turbineId).set(1);
            // },
            // setStatus2: function(turbineId){
            //     let ref = this.get('ref');
            //     ref.child('live-data/turbine-status/' + turbineId).set(2);
            // },
            // setStatus3: function(turbineId){
            //     let ref = this.get('ref');
            //     ref.child('live-data/turbine-status/' + turbineId).set(3);
            // }
        }

        // init: function(){
        //     var _this = this;
        //     let ref = this.get('ref');
        //
        //     ref.child('info/privacy').on("value", function (snapshot) {
        //         _this.setProperties({privacy: snapshot.val()});
        //     });
        //     ref.child('info/disclaimer').on("value", function (snapshot) {
        //         _this.setProperties({disclaimer: snapshot.val()});
        //     });
        //     ref.child('info/licenses').on("value", function (snapshot) {
        //         _this.setProperties({licenses: snapshot.val()});
        //     });
        //
        //     ref.child('live-data/turbine-status').on("child_added", function(snapshot){
        //         var Turbine = Ember.Object.extend().create({
        //             id: snapshot.key(),
        //             status: snapshot.val()
        //         });
        //
        //         _this.get('turbines').pushObject(Turbine);
        //     });
        //     ref.child('live-data/turbine-status').on("child_changed", function(snapshot){
        //         var turbine = _this.get('turbines').get(snapshot.key()-1);
        //         turbine.set("status", snapshot.val());
        //
        //         // _this.get('turbines').set(snapshot.key(), turbine);
        //     });
        // }
    });
});