define('gemini/controllers/app/files/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    saveUpdate: "",

    actions: {
      save: function save() {
        var item = this.store.createRecord('file', {
          name: this.get('name'),
          active: false,
          createdAt: new Date(),
          createdAtI: -new Date().getTime(),
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        this.set('question', '');
        this.set('saveUpdate', "Toegevoegd!");

        this.transitionToRoute('app.files.item', item);

        item.save();
      },
      cancel: function cancel() {
        this.transitionToRoute('app.files');
      }
    }
  });
});