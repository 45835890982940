define("gemini/controllers/app/users/add", ["exports", "ember-i18n"], function (exports, _emberI18n) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    saveUpdate: "",

    email: null,
    name: null,

    actions: {
      save: function save() {
        if (this.get('email') == null) {
          this.set('saveUpdate', (0, _emberI18n.translationMacro)("users.errors.invalid-email"));
          return;
        }
        var _this = this;

        this.get('store').query('user', {
          orderBy: "email",
          equalTo: this.get('email')
        }).then(function (users) {
          if (users && users.content) {
            if (users.content.length === 0) {
              return null;
            } else {
              throw "users.errors.email-already-in-use";
            }
          }
        }).then(function () {

          return _this.get('store').createRecord('user', {
            name: _this.get('name'),
            "last-signed-in": {},
            "previous-signed-in": {},
            // id: userResponse.uid,
            // email: userResponse.email,

            devices: [],
            resetPassword: true,
            "device-count": 0,
            email: _this.get('email'),
            createdAt: new Date(),
            createdAtI: -new Date().getTime(),
            updatedAt: new Date(),
            updatedAtI: -new Date().getTime()
          }).save();
        }).then(function (user) {
          _this.set('email', '');
          _this.set('name', '');
          _this.set('saveUpdate', "Toegevoegd!");

          _this.transitionToRoute('app.users.user', user);
        }).catch(function (error) {
          console.log(error);
          console.log(error.errors);
          _this.set('saveUpdate', (0, _emberI18n.translationMacro)(error));
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('app.users');
      }
    }

    // createUser(email, pass) {
    //   const auth = this.get('firebaseApp').auth();
    //   auth.createUserWithEmailAndPassword(email, pass).then((userResponse) => {
    //     const user = this.store.createRecord('user', {
    //       id: userResponse.uid,
    //       email: userResponse.email
    //     });
    //     return user.save();
    //   });
    // }
  });
});