define("gemini/controllers/app/files", ["exports", "ember-i18n"], function (exports, _emberI18n) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    "show-pagination": true,
    sortedModel: Ember.computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],

    columns: [{
      name: "name",
      label: "Naam",
      primary: true
    }, {
      name: "active",
      label: "Active",
      primary: false,
      style: Ember.String.htmlSafe('width: 15%')
    }],

    columnsSet: Ember.on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('files.file-name'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('visible'));
    })
  });
});