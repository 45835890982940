define("gemini/routes/app", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model: function model() {
      return this.store.find("user", this.get("session.currentUser.uid"));
    },

    afterModel: function afterModel(model) {

      // Check validity of sign in
      this.store.findAll("check").catch(this.actions.error.bind(this));

      //Catch permission_denied auto error
      Ember.Logger.error = this.actions.error.bind(this);

      // let _this = this;
      setInterval(function () {
        model.set("admin-last-active-from-web-panel", new Date());
        model.save();
      }, 60000); //every minute
    },

    actions: {
      error: function error(_error) {
        console.log(_error);
        console.log(_error.code);
        if (_error.code === 'PERMISSION_DENIED') {
          console.log("Signing out...");
          this.get("session").close();
          return this.transitionTo('signin');
        } else {
          // Let the route above this handle the error.
          return true;
        }
      }
    }

  });
});