define('gemini/routes/app/signout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.get("session").close();
      return this.transitionTo('signin');
    }
  });
});