define('gemini/components/ember-paper-wrapper', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    progress: 0,

    uploader: null,
    files: null,

    "upload-state": null,
    response: null,

    filesDidChange: function filesDidChange(files) {
      this.set('files', files);
    },

    setUploader: Ember.on('init', function () {
      this.configUploader();
    }),

    configUploader: function configUploader() {
      var _this2 = this;

      console.log("url", this.get('url'));
      this.set('uploader', _emberUploader.default.Uploader.create({
        url: this.get('url'),
        ajaxSettings: {
          headers: {
            'X-File-Access': 'private',
            'X-File-Name': this.get('file-name'),
            'X-Auth-Token': this.get('token')
          }
        }
      }));

      this.get('uploader').on('progress', function (e) {
        _this2.set("progress", e.percent);
      });

      this.get('uploader').on('didError', function (jqXHR, textStatus, errorThrown) {
        console.warn(jqXHR, textStatus, errorThrown);
      });
    },


    tokenListener: Ember.observer('token', "url", function () {
      this.configUploader();
    }),

    upload: Ember.observer('upload-state', function () {
      if (this.get('upload-state') === "upload") {
        var files = this.get('files');
        var _this = this;
        if (!Ember.isEmpty(files)) {
          this.get('uploader').upload(files[0], {
            type: this.get("file-type")
          }).then(function (response) {
            _this.set('response', response);
            _this.set('upload-state', 'ready');
            _this.set('files', null);
          }, function (e) {
            console.warn('upload error', e);
            _this.set('upload-state', 'ready');
          });
        } else {
          _this.set('upload-state', 'ready');
        }
      }
    })
  });
});