define("gemini/controllers/app/contact/message", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pageTitle: "Contact / Item",
    actions: {
      mail: function mail(email) {
        document.location.href = "mailto:" + email;
      },

      cancel: function cancel() {
        this.transitionToRoute('app.contact');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      }
    }
  });
});