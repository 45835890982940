define('gemini/models/newsitem', ['exports', 'ember-data', 'npm:moment'], function (exports, _emberData, _npmMoment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    subject: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr("date"),
    createdAtI: _emberData.default.attr("number"), // inverse
    updatedAt: _emberData.default.attr("date"),
    updatedAtI: _emberData.default.attr("number"), // inverse

    content: _emberData.default.attr(),

    messageShort: Ember.computed('message', function () {
      var message = '' + this.get('message');
      if (message.length > 100) {
        message = message.substring(0, 100) + "...";
      }
      return message;
    }),

    date: Ember.computed('date', {
      get: function get() {
        return (0, _npmMoment.default)(this.get('createdAt')).format('D/M/YYYY');
      },
      set: function set(key, value) {
        var date = (0, _npmMoment.default)(value, 'D/M/YYYY');
        this.set('createdAt', date.toDate());
        this.set('createdAtI', -date.valueOf());
        return value;
      }
    }),

    paginationLink: "app.news.item"
  });
});