define('gemini/components/content-manager', ['exports', 'gemini/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    content: [],
    "firebase-end-point": "/",
    "show-save-button": true,

    "image-uploads": [],
    uploadUrl: _environment.default.backEndUrl + "/file-upload",

    "save-trigger": null,
    "is-saving": false,

    ref: Ember.inject.service('firebase'),

    actions: {
      removeContent: function removeContent(item) {
        this.get('content').removeObject(item);
      },
      addContent: function addContent(index) {
        if (index === "end") {
          this.get('content').pushObject({ type: 'new' });
        } else {
          this.get('content').insertAt(index, { type: 'new' });
        }
      },
      setNewContentType: function setNewContentType(item, type) {
        Ember.set(item, 'type', type);
      },
      moveContent: function moveContent(direction, index) {
        var switchIndex = index + (direction === 'up' ? -1 : 1);

        var content = this.get('content');
        var tmp = content[switchIndex];
        this.get('content').replace(switchIndex, 1, [content[index]]);
        this.get('content').replace(index, 1, [tmp]);
      },
      saveContent: function saveContent() {
        this.saveContent();
      },
      testIsSaving: function testIsSaving() {
        this.set('is-saving', !this.get('is-saving'));
      }
    },

    saveContent: Ember.observer('save-trigger', function () {
      this.set("is-saving", true);
      var uploadPromises = [];
      if (this.get('image-uploads') != null) {
        this.get('image-uploads').forEach(function (imageUpload) {
          uploadPromises.push(imageUpload.upload());
        });
      }
      var _this = this;
      var ref = _this.get('ref');
      Ember.RSVP.Promise.all(uploadPromises).then(function () {
        return ref.child(_this.get('firebase-end-point')).set(_this.get('content')); // eerste uploaden van ima
      }).then(function () {
        _this.set("is-saving", false);
      });
    }),

    isSavingListener: Ember.observer("is-saving", function () {
      this.sendAction('onSavingChanged', this.get('is-saving'));
    }),

    linkValidation: [{
      'message': 'Link is niet goed ingevuld. De link moet beginnen met http:// of https://',
      validate: function validate(value) {
        return (/^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value)
        );
      }
    }],

    initComponent: Ember.observer('firebase-end-point', function () {
      var _this = this;
      var ref = this.get('ref');
      ref.child(this.get("firebase-end-point")).on("value", function (snapshot) {
        var content = [];
        if (snapshot.val() != null) {
          snapshot.val().forEach(function (item) {
            content.pushObject(item);
          });
        }
        _this.set('content', content);
      });
    }),

    init: function init() {
      this._super();
      this.initComponent();
    }
  });
});