define('gemini/helpers/device-model-name', ['exports', 'npm:ios-device-list'], function (exports, _npmIosDeviceList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.deviceModelName = deviceModelName;
  function deviceModelName(params) {
    var identifier = params[0];
    if (typeof identifier !== "string") {
      return "";
    }
    var iosModel = _npmIosDeviceList.default.generationByIdentifier(identifier);
    return iosModel !== undefined ? iosModel : identifier;
  }

  exports.default = Ember.Helper.helper(deviceModelName);
});