define('gemini/controllers/app/users', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    i18n: Ember.inject.service(),
    sortedModel: Ember.computed.sort('model', 'sorting'),
    sorting: ['createdAtI'],

    "show-pagination": true,

    columns: [{
      name: "name",
      label: "Name",
      primary: true,
      style: Ember.String.htmlSafe('width: 40%')
    }, {
      name: "last-signed-in-text",
      label: "Last signed in..",
      style: Ember.String.htmlSafe('width: 30%')
    }, {
      name: "device-count",
      label: "# devices",
      primary: false,
      style: Ember.String.htmlSafe('width: 15%')
    }, {
      name: "disabled",
      label: "Disabled",
      primary: false,
      style: Ember.String.htmlSafe('width: 15%')
    }],

    columnsSet: Ember.on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('users.name'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('users.last-signed-in'));
      this.set('columns.2.i18n', this.get('i18n'));
      this.set('columns.2.label', (0, _emberI18n.translationMacro)('users.number-of-devices'));
      this.set('columns.3.i18n', this.get('i18n'));
      this.set('columns.3.label', (0, _emberI18n.translationMacro)('disabled'));
    })
  });
});