define('gemini/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    sent: _emberData.default.attr('boolean'),

    createdAt: _emberData.default.attr("date"),
    createdAtI: _emberData.default.attr("number"), // inverse
    processedAt: _emberData.default.attr("date")

  });
});