define("gemini/controllers/app/faq/item", ["exports", "ember-i18n"], function (exports, _emberI18n) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    pageTitle: "Frequently Asked Questions / Item",
    i18n: Ember.inject.service(),
    saveUpdate: "",

    saveTrigger: null,
    "is-saving": false, // used in UI
    "fb-is-saving": false, // saving firebase
    "cm-is-saving": false, // saving content manager

    actions: {
      save: function save() {
        var _this = this;
        this.set('is-saving', true);
        this.set('fb-is-saving', true);

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        model.save().then(function () {
          _this.set('fb-is-saving', false);
        });

        this.set('saveTrigger', Math.random());
        // this.trigger('saveContent');
      },
      cancel: function cancel() {
        this.transitionToRoute('app.faq');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      }
    },

    isSavingListener: Ember.observer("fb-is-saving", "cm-is-saving", function () {
      if (!this.get("cm-is-saving") && !this.get("fb-is-saving")) {
        this.set("is-saving", false);
      } else {
        this.set('is-saving', true);
      }
    }),

    saveUpdateListener: Ember.observer("is-saving", function () {
      if (this.get("is-saving")) {
        this.set("saveUpdate", (0, _emberI18n.translationMacro)("saving"));
      } else {
        this.set("saveUpdate", (0, _emberI18n.translationMacro)("saved"));
        var _this = this;
        Ember.run.later(function () {
          _this.set('saveUpdate', '');
        }, 5000);
      }
    })
  });
});