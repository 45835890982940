define('gemini/models/log', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    source: _emberData.default.attr("string"),
    action: _emberData.default.attr("string"),

    createdAt: _emberData.default.attr("date"),
    createdAtI: _emberData.default.attr("number"), // inverse

    paginationLink: "app.logs.item",

    user: _emberData.default.attr("string"),
    device: _emberData.default.attr("string"),
    ip: _emberData.default.attr("string"),
    result: _emberData.default.attr("string"),
    level: _emberData.default.attr("string"),

    displayname: Ember.computed("user", function () {
      if (this.data.user !== null) {
        return this.data.user;
      } else {
        return this.data.source;
      }
    }),

    userObject: Ember.computed("user", function () {
      var user = this.get("user");
      if (user) {
        // console.log("userObject", this.data.user)
        return this.store.findRecord('user', user);
      }
    }),

    shortDate: Ember.computed("createdAt", function () {
      var d = this.get("createdAt");
      if (d && !isNaN(d.getTime())) {
        return [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' + [("00" + d.getHours()).slice(-2), ("00" + d.getMinutes()).slice(-2), ("00" + d.getSeconds()).slice(-2)].join(':');
      }
    }),
    deviceObject: null,
    deviceObjectListener: Ember.observer("device", function () {
      var _this = this;

      if (this.data.user !== null && this.data.user !== "null") {
        return this.store.findRecord('user', this.data.user).then(function (v) {
          // console.log(v.get("devices")[this.data.device]);
          _this.set('deviceObject', v.get("devices")[_this.data.device]);
        });
      }
    })
  });
});