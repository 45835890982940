define('gemini/routes/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        i18n: Ember.inject.service(),

        beforeModel: function beforeModel() {
            var _this = this;

            return this.get('session').fetch().catch(function () {
                _this.transitionTo('signin');
            });
        },

        actions: {
            transition: function transition() {
                this.transitionToRoute.apply(this, arguments);
            }
        }
    });
});