define("gemini/routes/app/files", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // beforeModel(){
    //     return this.get(this,'session').fetch().catch(function(){});
    // },
    model: function model() {
      return this.store.findAll("file");
    }
  });
});