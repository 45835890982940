define("gemini/helpers/nltobr", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.nltobr = nltobr;
    function nltobr(params) {
        return new Ember.Handlebars.SafeString(params[0].replace(/\n/g, "<br />"));
    }

    exports.default = Ember.Helper.helper(nltobr);
});