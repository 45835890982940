define("gemini/controllers/app/users/user", ["exports", "ember-i18n"], function (exports, _emberI18n) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),

    saveUpdate: "",

    saveTrigger: null,

    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        model.save().then(function () {
          _this.set('saveUpdate', 'Opgeslagen!');
        });

        this.set('saveTrigger', Math.random());
        // this.trigger('saveContent');
      },
      resetPassword: function resetPassword() {
        var _this = this;
        var model = this.get('model');
        model.setProperties({
          resetPassword: true,
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });
        model.save().then(function () {
          _this.set('saveUpdate', (0, _emberI18n.translationMacro)("users.password-reset-email-sent"));
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('app.users');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      },
      signOutDevice: function signOutDevice(device) {
        this.set("model.devices." + device + ".active", false);
        this.get("model").save();
      },
      disableDevice: function disableDevice(device, disabled) {
        if (!disabled) {
          this.set("model.devices." + device + ".active", false);
          this.set("model.devices." + device + ".disabled", true);
        } else {
          this.set("model.devices." + device + ".disabled", false);
        }
      }
    },

    activeListener: Ember.observer("model.disabled", function () {
      if (this.get('model.disabled')) {
        var devices = Ember.get(this, 'model.devices');
        if (devices != null) {
          for (var device in devices) {
            this.set("model.devices." + device + ".active", false);
          }
        }
      }
    }),

    devices: Ember.computed('model.devices', function () {
      var object = Ember.get(this, 'model.devices');

      if (object === null) {
        return null;
      }

      var keys = Object.keys(object);

      return keys.map(function (key) {
        return { key: key, value: object[key] };
      });
    })
  });
});