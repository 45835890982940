define('gemini/controllers/app/push', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedModel: Ember.computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],
    i18n: Ember.inject.service(),

    columns: [{
      name: "title",
      label: "Titel",
      primary: true,
      style: Ember.String.htmlSafe('width: 50%')
    }, {
      name: "sent",
      label: "Verstuurd",
      // type: "boolean",
      style: Ember.String.htmlSafe('width: 20%')
    }, {
      name: "createdAt",
      label: "Datum",
      type: "date",
      style: Ember.String.htmlSafe('width: 30%')
    }],

    columnsSet: Ember.on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('push.title'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('push.sent'));
      this.set('columns.2.i18n', this.get('i18n'));
      this.set('columns.2.label', (0, _emberI18n.translationMacro)('push.date'));
    }),

    title: null,
    message: null,
    image: null,
    upload: [],
    actionValue: null

  });
});