define('gemini/components/paper-confirm-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    confirm: false,

    actions: {
      askConfirm: function askConfirm() {
        this.set('confirm', true);
      },
      cancelConfirm: function cancelConfirm() {
        this.set('confirm', false);
      },
      performAction: function performAction(params) {
        this.set('confirm', false);
        params();
      }
    }
  });
});