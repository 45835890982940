define('gemini/controllers/app/faq', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    i18n: Ember.inject.service(),
    sortedModel: Ember.computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],

    "show-pagination": true,

    columns: [{
      name: "question",
      label: "Vraag",
      primary: true
    }, {
      name: "active",
      label: "Active",
      primary: false,
      style: Ember.String.htmlSafe('width: 15%')
    }],

    columnsSet: Ember.on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('faq.question'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('visible'));
    })
  });
});