define('gemini/components/paper-remove-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        warn: false,
        actions: {
            areYouSure: function areYouSure() {
                this.set('warn', !this.get('warn'));
            }
        }
    });
});