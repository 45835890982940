define("gemini/locales/nl/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    "add": "Toevoegen",
    "cancel": "Annuleren",
    "remove": "Verwijderen",
    "save": "Opslaan",
    "saved": "Opgeslagen",
    "saving": "Bezig met opslaan",
    "confirm": "Bevestigen",
    "component": "Onderdeel",
    "sign-in": "Inloggen",

    "active": "Actief",
    "inactive": "Inactief",

    "visible": "Zichtbaar",
    "hidden": "Verborgen",
    "disabled": "Uitgeschakeld",

    "true": "Ja",
    "false": "Nee",

    "index": {
      "in-menu": "Dashboard",
      "in-toolbar": "Dashboard"
    },

    "contact": {
      "in-menu": "Contact",
      "in-toolbar": "Contact",

      "message": {
        "in-toolbar": "Contact / Bericht",
        "message": "Bericht",
        "emailaddress": "E-mail Adres",
        "answer": "Antwoorden"
      }
    },

    "analytics": {
      "in-menu": "Gebruiksstatistieken"
    },

    "faq": {
      "in-menu": "FAQ",
      "in-toolbar": "Frequently Asked Questions",
      "question": "Vraag",
      "add-title": "FAQ Vraag Toevoegen",

      "add": {
        "in-toolbar": "Frequently Asked Questions / Vraag Toevoegen"
      }
    },

    "info": {
      "in-menu": "Informatie",
      "in-toolbar": "Informatie",

      "short": "beknopt",
      "detailed": "volluit",

      "short-description": "Korte omschrijving",
      "title": "Titel"
    },

    "news": {
      "in-menu": "Nieuws",
      "in-toolbar": "Nieuws",
      "subject": "Onderwerp",
      "message": "Bericht",
      "add-title": "Nieuwsbericht toevoegen",
      "send-push-notification": "Verstuur pushnotificatie",
      "visible": "Zichtbaar",
      "date": "Datum",

      "add": {
        "in-toolbar": "Nieuws / Bericht Toevoegen"
      }
    },

    "push": {
      "in-menu": "Pushnotificaties",
      "in-toolbar": "Pushnotificaties",
      "new-notification": "Nieuwe push notificatie",

      "title": "Titel",
      "message": "Bericht",
      "send": "Verstuur",

      "sent": "Verstuurd",
      "date": "Datum",
      "new": "Nieuw bericht"
    },

    "files": {
      "in-menu": "Bestanden",
      "in-toolbar": "Bestanden",

      "file": "Bestand",
      "file-name": "Bestandsnaam",

      "item": {
        "in-toolbar": "Bestanden / Bestand"
      },
      "add": {
        "in-title": "Bestand Toevoegen",
        "in-toolbar": "Bestanden / Bestand Toevoegen"
      }
    },

    "settings": {
      "in-menu": "Instellingen",
      "in-toolbar": "Algemene instellingen",

      "setting": {
        "projections": "Projecties",
        "descriptions": "Uitleg",
        "contact": "Contact Formulier"
      },

      "descriptions": {
        "select-component": "Selecteer een onderdeel.",
        "live": {
          "windspeed": "Windsnelheid",
          "winddirection": "Windrichting",
          "co2": "CO2",
          "lamps": "Spaarlampen",
          "households": "Huishoudens",
          "turbine-status": "Turbine Status",
          "waveheight": "Golfhoogte"
        },
        "history": {
          "production": "Totale productie",
          "hours": "Vollasturen",
          "co2": "CO2",
          "households": "Huishoudens"
        },
        "other": {
          "privacy": "Privacy Policy",
          "disclaimer": "Disclaimer",
          "licenses": "Open Licenties"
        },
        "status": {
          "wind-high": "Te veel wind",
          "wind-low": "Te weinig wind",
          "maintenance": "Onderhoud",
          "environment": "Vogels/vleermuizen",
          "ice": "IJs"
        },
        "groups": {
          "live": "Live",
          "history": "Historisch",
          "other": "Anders",
          "status": "Turbinestatus"
        }
      }
    },

    "signout": {
      "in-menu": "Uitloggen"
    },

    "users": {
      "in-menu": "Gebruikersbeheer",
      "in-toolbar": "Gebruikersbeheer",
      "last-signed-in": "Laatste Keer Ingelogd",

      "name": "Naam",
      "email": "E-mail adres",
      "devices": "Aparaten",
      "device-name": "Apparaatnaam",
      "device-allow-access": "Toegang Toestaan",
      "device-allowed": "Toestaan",
      "device-not-allowed": "Niet toestaan",
      "number-of-devices": "Aantal Apparaten",
      "disabled": "Uitgeschakeld",
      "sign-out-device": "Uitloggen",

      "reset-password": "Wachtwoord Resetten",
      "password-reset-email-sent": "E-mail met reset instructies is verzonden.",

      "add": {
        "in-toolbar": "Gebruikersbeheer / Gebruiker Toevoegen",
        "title": "Gebruiker Toevoegen"
      },

      "user": {
        "in-toolbar": "Gebruikersbeheer / Overzicht Gebruiker"
      },

      "errors": {
        "invalid-email": "Voer een geldig e-mail adres in.",
        "email-already-in-use": "Dit e-mail adres is al in gebruik."
      }
    },

    "logs": {
      "in-menu": "Logboek",
      "in-toolbar": "Logboek",

      "source": "Bron",
      "time": "Tijdstip",
      "action": "Actie",
      "level": "Niveau",
      "user": "Gebruiker",
      "device": "Apparaat",
      "ip": "IP Adres",
      "result": "Resultaat",
      "error": "Foutmelding",

      "levels": {
        "default": "Normaal"
      }
    },

    "misc": {
      "feedback": "Feedback"
    },

    // components
    "c": {
      "fb-pagination": {
        "search": "Zoeken",
        "previous": "vorige",
        "next": "volgende"
      },
      "content-manager": {
        "types": {
          "text": "Tekst",
          "image": "Afbeelding",
          "movie": "Video",
          "two-columns": "Twee kolommen",
          "link": "Link",
          "heading": "Koptekst"
        },
        "link": {
          "text": "Link tekst",
          "location": "Link locatie"
        },
        "movie": {
          "url": "Video URL",
          "explanation": "Voer een Vimeo link in (bijvoorbeeld \"https://vimeo.com/12345678\")"
        }
      },
      "paper-remove-button": {
        "confirm": "bevestig"
      },
      "feedback-manager": {
        "comments": "Opmerkingen",
        "no-comments": "Er zijn nog geen opmerkingen achtergelaten.",
        "no-feedback": "Er is nog geen feedback beschikbaar."
      },
      "image-uploader": {
        "select": "selecteer"
      },
      "private-file-upload": {
        "uploaded": "Bestand is geüpload"
      }
    }
  };
});