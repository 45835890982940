define('gemini/controllers/app', ['exports', 'gemini/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pageTitle: "Live Megawatt",
    appLogo: "assets/images/logo_" + _environment.default.CURRENT_APP + ".png",

    modules: _environment.default.modules,

    firebaseApp: Ember.inject.service(),
    router: Ember.inject.service(),

    actions: {
      openUrl: function openUrl(url) {
        window.open(url);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      var appCtrl = Ember.getOwner(this).lookup('controller:application');
      this.set('appCtrl', appCtrl);
    }
  });
});