define('gemini/helpers/if_odd', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.if_odd = if_odd;
    function if_odd(conditional, options) {
        console.log(conditional);
        console.log(options);
        return conditional % 2 === 1;
    }

    exports.default = Ember.Helper.helper(if_odd);
});