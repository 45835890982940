define('gemini/routes/signin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {

      if (this.get("session.currentUser") !== undefined) {
        this.transitionTo('app');
      }
    }
  });
});