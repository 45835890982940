define("gemini/components/private-file-upload", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Component.extend({
    firebase: inject.service(),
    session: inject.service(),

    "token": null,

    setToken: Ember.on("init", function () {
      var _this = this;
      // console.log('set token');
      this.get('session.currentUser').getToken(false).then(function (token) {
        // console.log("token", token);
        _this.set("token", token);
      });
    }),

    upload: Ember.observer('upload-state', function () {
      if (this.get('upload-state') === "ready") {}
    })
  });
});