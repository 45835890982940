define('gemini/router', ['exports', 'gemini/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('signin');
    this.route('app', { path: '/', resetNamespace: true }, function () {
      this.route('faq');
      this.route('faq.add', { path: 'faq/add' });
      this.route('faq.item', { path: 'faq/item/:faq_id' });

      this.route('news');
      this.route('news.add', { path: 'news/add' });
      this.route('news.item', { path: 'news/item/:newsitem_id' });

      this.route('info');

      this.route('settings', function () {
        this.route('privacy', { path: 'privacy' });
        this.route('disclaimer', { path: 'disclaimer' });
        this.route('licenses', { path: 'licenses' });
        this.route('projections', { path: 'projections' });
        this.route('descriptions', { path: '' });
        this.route('turbinestatus', { path: 'turbine-status' });
        this.route('contact');
      });

      this.route('push');
      this.route('push.new', { path: 'push/new' });

      this.route('contact');
      this.route('contact.message', { path: 'contact/message/:form_id' });

      this.route('users');
      this.route('users.user', { path: 'users/user/:user_id' });
      this.route('users.add', { path: 'users/add' });

      this.route('signout');
      this.route('files');
      this.route('files.item', { path: 'files/item/:file_id' });
      this.route('files.add', { path: 'files/add' });

      this.route('logs');
      this.route('logs.item', { path: 'logs/item/:log_id' });
    });
  });

  exports.default = Router;
});