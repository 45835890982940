define('gemini/controllers/app/contact', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        i18n: Ember.inject.service(),

        sortedModel: Ember.computed.sort('model', 'sorting'),
        sorting: ['createdAtI'],

        columns: [{
            name: "mail",
            label: "E-mail adres",
            primary: true,
            style: Ember.String.htmlSafe('width: 40%')
        }, {
            name: "message",
            label: "Bericht",
            style: Ember.String.htmlSafe('width: 60%')
        }],

        columnsSet: Ember.on('init', function () {
            this.set('columns.0.i18n', this.get('i18n'));
            this.set('columns.0.label', (0, _emberI18n.translationMacro)('contact.message.emailaddress'));
            this.set('columns.1.i18n', this.get('i18n'));
            this.set('columns.1.label', (0, _emberI18n.translationMacro)('contact.message.message'));
        })
    });
});