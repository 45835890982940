define('gemini/controllers/app/news/item', ['exports', 'ember-i18n', 'gemini/config/environment'], function (exports, _emberI18n, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(Ember.Evented, {

    pageTitle: "News / Item",
    i18n: Ember.inject.service(),
    saveUpdate: "",

    saveTrigger: null,
    "is-saving": false, // used in UI
    "fb-is-saving": false, // saving firebase
    "cm-is-saving": false, // saving content manager
    linkOnly: _environment.default.newsTypeLinksOnly,

    linkValidation: [{
      'message': 'Link is niet goed ingevuld. De link moet beginnen met http://',
      validate: function validate(value) {
        return (/^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value)
        );
      }
    }],

    actions: {
      save: function save() {
        var _this = this;
        this.set('is-saving', true);
        this.set('fb-is-saving', true);

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        model.save().then(function () {
          _this.set('fb-is-saving', false);
        });

        this.set('saveTrigger', Math.random());
        // this.trigger('saveContent');
      },
      cancel: function cancel() {
        this.transitionToRoute('app.news');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      },

      push: function push() {
        // var model = this.get('model');
        //
        // var data = {
        //   "title": model.get('subject'),
        //   "action-type": "news",
        //   "action-key": model.get('id'),
        // };

        // Ember.$.ajax("https://fcm.googleapis.com/fcm/send", {
        //   type: "POST",
        //   dataType: "json",
        //   beforeSend: function (xhr) {
        //     xhr.setRequestHeader('Authorization', "key=AIzaSyCiGmMbdIp8Djw48XpuTfNpx6_2s5ft3FQ");
        //     xhr.setRequestHeader('Content-Type', "application/json");
        //   },
        //   data: JSON.stringify({
        //     data: data,
        //     to: "/topics/news",
        //     notification: {
        //       body: model.get('subject'),
        //       icon: 'ic_notification',
        //       click_action: 'OPEN_ACTIVITY_NEWS',
        //       color: '#388E3C'
        //       // title: model.get('subject')
        //     }
        //   })
        // });
      }
    },

    isSavingListener: Ember.observer("fb-is-saving", "cm-is-saving", function () {
      if (!this.get("cm-is-saving") && !this.get("fb-is-saving")) {
        this.set("is-saving", false);
      } else {
        this.set('is-saving', true);
      }
    }),

    saveUpdateListener: Ember.observer("is-saving", function () {
      if (this.get("is-saving")) {
        this.set("saveUpdate", (0, _emberI18n.translationMacro)("saving"));
      } else {
        this.set("saveUpdate", (0, _emberI18n.translationMacro)("saved"));
        var _this = this;
        Ember.run.later(function () {
          _this.set('saveUpdate', '');
        }, 5000);
      }
    })
  });
});