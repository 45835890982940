define('gemini/helpers/fb-get', ['exports', 'gemini/helpers/format-date', 'gemini/helpers/format-datetime'], function (exports, _formatDate, _formatDatetime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fbGet = fbGet;
  function fbGet(params) {
    var type = params[1].type;
    var value = params[0].get(params[1].name);

    // console.log('fb-get', typeof value)
    if (type === undefined || type === "string") {
      return value;
    } else if (type === "date") {
      return _formatDate.default.compute(value);
    } else if (type === "datetime") {
      return _formatDatetime.default.compute(value);
    }
  }

  exports.default = Ember.Helper.helper(fbGet);
});