define('gemini/components/image-upload', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Component.extend(Ember.Evented, {
    firebaseApp: Ember.inject.service(),
    session: inject.service(),
    file: null,
    "upload-file": null,
    "image-url": null,
    "image-width": null,
    "image-height": null,

    observer1: Ember.observer('image-url', function () {// keep, allows for (eq image-url undefined) in .hbs
      // console.log(this.get('image-url'));
    }),

    "new-image": null,

    "upload-state": null,
    uploader: null,
    isLoading: false,

    onInit: Ember.on('init', function () {
      var _this = this;
      this.get('session.currentUser').getToken(false).then(function (token) {
        _this.set("token", token);
        _this.configUploader();
      });
    }),

    configUploader: function configUploader() {
      var _this2 = this;

      this.set('uploader', _emberUploader.default.Uploader.create({
        url: this.get('url'),
        ajaxSettings: {
          headers: {
            'X-File-Access': 'public',
            'X-Auth-Token': this.get('token')
          }
        }
      }));

      this.get('uploader').on('progress', function (e) {
        _this2.set("progress", e.percent);
      });

      this.get('uploader').on('didError', function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus, errorThrown);
      });
    },


    actions: {
      fileSelected: function fileSelected(element) {

        this.set('upload-file', element.target.files);

        if (element.target.files && element.target.files[0]) {
          var reader = new FileReader();
          var _this = this;
          reader.onload = function (e) {
            _this.set('new-image', e.target.result);
          };

          reader.readAsDataURL(element.target.files[0]);

          if (this.get('image-upload-array') !== undefined) {
            this.get('image-upload-array').pushObject(this);
          }
        }
      },
      openFileDialog: function openFileDialog(className) {
        Ember.$("input[type='file']." + className).click();
      }
    },

    upload: function upload() {
      var files = this.get('upload-file');
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(files)) {
          _this.get('uploader').upload(files[0]).then(function (value) {

            if (!_this.isDestroyed) {
              _this.set('new-image', null);
              _this.set('isLoading', false);
              _this.set('image-url', value["url"]);
              _this.set('upload-file', null);
              _this.set('image-width', value['width']);
              _this.set('image-height', value['height']);
              resolve(value["url"]);
            } else {
              reject();
            }
          }, function (e) {
            console.warn('upload error', e);
            reject(e);
          });
        } else {
          resolve();
        }
      });
    },

    init: function init() {
      this._super();
    }
  });
});