define("gemini/routes/app/logs", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      start: {
        refreshModel: true
      }
    },

    model: Ember.observer("filterWarning", function () {
      // console.log("start-at", params["start"]);
      return this.store.query("log", { orderBy: 'createdAtI', limitToFirst: 200 });
      // return this.store.query("log", {orderBy: 'createdAtI', limitToFirst: 20, startAt: params["start"]});
    })
  });
});