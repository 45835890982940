define('gemini/controllers/app/news/add', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        saveUpdate: "",

        actions: {
            save: function save() {
                var item = this.store.createRecord('newsitem', {
                    subject: this.get('subject'),
                    active: false,
                    createdAt: new Date(),
                    createdAtI: -new Date().getTime(),
                    updatedAt: new Date(),
                    updatedAtI: -new Date().getTime(),
                    content: {}
                });

                this.set('subject', '');
                this.set('message', '');
                this.set('saveUpdate', "Toegevoegd!");

                this.transitionToRoute('app.news.item', item);

                item.save().catch(function (e) {
                    console.log(e, e.errors);
                });
            },
            cancel: function cancel() {
                this.transitionToRoute('app.news');
            }
        }
    });
});