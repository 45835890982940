define('gemini/helpers/format-datetime', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDatetime = formatDatetime;
  function formatDatetime(value) {

    if (!value || typeof value === 'undefined') {
      return "";
    }

    if (typeof value[0] !== 'undefined') {
      value = value[0];
    }

    var d = new Date(value);
    if (isFinite(d)) {
      return ("00" + d.getHours()).slice(-2) + ":" + ("00" + d.getMinutes()).slice(-2) + ":" + ("00" + d.getSeconds()).slice(-2) + " " + d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    } else {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(formatDatetime);
});